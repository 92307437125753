
export function isMobile() {
    const isSmallScreen = window.innerWidth <= 724;
    const acceptsTouchEvents = ('ontouchstart' in document.documentElement) ? true : false;
    return isSmallScreen && acceptsTouchEvents;
}

export function isTablet() {
    const isBiggerScreen = window.innerWidth > 724;
    const acceptsTouchEvents = ('ontouchstart' in document.documentElement) ? true : false;
    return isBiggerScreen && acceptsTouchEvents;
}

export function isDesktop() {
    return !isMobile() && !isTablet();
}

export function isAndroid() {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
}

export function isMacintosh() {
    return window.navigator.platform.indexOf('Mac') > -1;
}

export function isWindows() {
    return window.navigator.platform.indexOf('Win') > -1;
}
