import React from 'react';
import { isEqual } from 'lodash';
import styled, { keyframes, css } from 'styled-components';

import { authenticateWithName } from './authentication';
import { isDesktop } from './environment';

import TextField from './TextField';

import iconLock from './lock.svg';
import iconSend from './send.svg';

const ShakeAnimation = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
`;

const Root = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => props.shaking ? css`
        animation: ${ShakeAnimation} 0.82s cubic-bezier(0.36,0.07,0.19,0.97) both;
        transform: translate3d(0,0,0);
        backface-visibility: hidden;
        perspective: 1000px;
    ` : ''}
`;

const Image = styled.img`
    flex: 0 0 auto;
    pointer-events: none;
`;

const NameField = styled.div`
    flex: 0 0 auto;
    width: 326px;
    height: 64px;
    margin: 0 0 0 18px;
    background-color: white;
    border-radius: 16px;
    border: 1px solid rgba(0,0,0,0.1);
    overflow: hidden;
    display: flex;
    align-items: center;
`;

const Name = styled.div`
    flex: 1 1 auto;
    margin: 0 12px 0 24px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    user-select: text;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
`;

const SubmitButton = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    width: 70px;
    background-color: #139EC2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;


class Login extends React.Component {
    constructor(props) {
        super(props);

        this.onReturn = this.onReturn.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onTextRender = this.onTextRender.bind(this);
        this.onRender = this.onRender.bind(this);

        this.state = { name: '', errorCount: 0, shaking: false };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        this.focus();
    }

    focus() {
        if (this.textField && isDesktop()) {
            this.textField.focus();
        }
    }

    submit() {
        const { onSuccess } = this.props;
        const { name } = this.state;

        const isValid = authenticateWithName(name);
        if (isValid) {
            this.textField.blur();
            if (onSuccess) {
                onSuccess(name);
            }
        } else {
            this.setState({ name: '', errorCount: this.state.errorCount + 1, shaking: true }, () => {
                this.focus();

                setTimeout(() => {
                    this.setState({ shaking: false });
                }, 2000);
            });
        }
    }

    onReturn() {
        this.submit();
    }

    onChange(text) {
        this.setState({ name: text });
    }

    onSubmitClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.submit();
    }

    onTextRender(textField) {
        this.textField = textField;
    }

    onRender(view) {
        this.view = view;
    }

    render() {
        const { name, errorCount, shaking } = this.state;

        let nameButton;
        if (name.length > 0) {
            nameButton = (
                <SubmitButton onClick={this.onSubmitClick}>
                    <Image src={iconSend} />
                </SubmitButton>
            )
        }

        return (
            <Root ref={this.onRender} shaking={shaking}>
                <Image src={iconLock} />
                <NameField>
                    <Name>
                        <TextField key={errorCount} ref={this.onTextRender} text={name} placeholder={'What\'s your name?'} onChange={this.onChange} onReturn={this.onReturn} />
                    </Name>
                    {nameButton}
                </NameField>
            </Root>
        );
    }
}

export default Login;