import React from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { easing, tween, spring, styler } from 'popmotion';

import { isAuthenticated } from './authentication';

import Main from './Main';
import Login from './Login';

import './App.css';
import 'draft-js/dist/Draft.css';
import iconBackground from './background.jpg';

const Root = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
`;

const Background = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(20px);
    transform: scale(1.1);
    pointer-events: none;
`;

const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
`;

const ROUTE = {
    LOGIN: 0,
    MAIN: 1
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.onSuccess = this.onSuccess.bind(this);
        this.onLoginRender = this.onLoginRender.bind(this);
        this.onOverlayRender = this.onOverlayRender.bind(this);
        this.onBackgroundRender = this.onBackgroundRender.bind(this);

        this.state = { route: isAuthenticated() ? ROUTE.MAIN : ROUTE.LOGIN, animatingLogin: false };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
            return false;
        }
        return true;
    }

    onSuccess() {
        console.log('success');
        if (this.loginView && this.loginView.view && this.overlayView && this.backgroundView) {
            this.setState({ animatingLogin: true }, () => {
                const loginStyler = styler(this.loginView.view);
                spring({
                    stiffness: 700,
                    damping: 42,
                    from: { y: 0 },
                    to: { y: - 0.5 * window.innerHeight - 80 },
                }).start({
                    update: (v) => loginStyler.set(v),
                    complete: () => {
                        this.setState({ route: ROUTE.MAIN, animatingLogin: false });
                    }
                });
                const overlayStyler = styler(this.overlayView);
                tween({
                    duration: 200,
                    ease: easing.easeInOut,
                    from: { opacity: 1 },
                    to: { opacity: 0 }
                }).start({
                    update: (v) => overlayStyler.set(v)
                });
                const backgroundStyler = styler(this.backgroundView);
                tween({
                    duration: 200,
                    ease: easing.easeInOut,
                    from: { opacity: 1 },
                    to: { opacity: 0 }
                }).start({
                    update: (v) => backgroundStyler.set(v)
                });
            });
        } else {
            this.setState({ route: ROUTE.MAIN });
        }
    }

    onLoginRender(loginView) {
        this.loginView = loginView;
    }

    onOverlayRender(overlayView) {
        this.overlayView = overlayView;
    }

    onBackgroundRender(backgroundView) {
        this.backgroundView = backgroundView;
    }

    render() {
        const { route, animatingLogin } = this.state;

        let main;
        if (route === ROUTE.MAIN || animatingLogin) {
            main = <Main />;
        }

        let login;
        if (route === ROUTE.LOGIN) {
            login = <Login ref={this.onLoginRender} onSuccess={this.onSuccess} />;
        }

        return ( 
            <Root>
                {main}
                { route === ROUTE.LOGIN ? <Background ref={this.onBackgroundRender} src={iconBackground} /> : undefined }
                { route === ROUTE.LOGIN ? <Overlay ref={this.onOverlayRender} /> : undefined }
                {login}
            </Root>
        );
    }
}

export default App;
