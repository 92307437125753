
export const AUTHENTICATED_GUEST_ID = 'AUTHENTICATED_GUEST_ID';

function parse(toParse) {
    if (!toParse) {
        return undefined;
    }

    let result;

    try {
        result = JSON.parse(toParse);
    } catch (e) {
        console.log('encountered error in JSON parsing: ', e);
        console.log(toParse);
        result = undefined;
    }

    return result;
}

function stringify(toStringify) {
    if (!toStringify) {
        return undefined;
    }

    let result;

    try {
        result = JSON.stringify(toStringify);
    } catch (e) {
        console.log('encountered error in JSON stringify: ', e);
        result = undefined;
    }

    return result;
}


export function getValue(key) {
    let value;
    if (window.localStorage) {
        const valueStr = window.localStorage.getItem(key);
        if (valueStr) {
            try {
                value = parse(valueStr);
            } catch (err) {
                console.log(`error parsing ${key} from local storage`, err);
            }
        }
    }
    return value;
}

export function setValue(key, value) {
    if (window.localStorage) {
        if (value) {
            window.localStorage.setItem(key, stringify(value));
        } else {
            window.localStorage.removeItem(key);
        }
    }
}
