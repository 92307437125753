import React from 'react';
import { Editor, EditorState, ContentState, getDefaultKeyBinding, CompositeDecorator, SelectionState, Modifier } from 'draft-js';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import { isAndroid, isWindows } from './environment';

const Input = styled.input`
    font-size: inherit;
    width: 100%;
    height: 100%;
    color: #333333;
    border: none;
    -webkit-appearance: none;
    outline: none;
`;

class TextField extends React.Component {
    constructor(props) {
        super(props);

        this.onRender = this.onRender.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onReturn = this.onReturn.bind(this);
        this.handleBeforeInput = this.handleBeforeInput.bind(this);
        this.handlePastedText = this.handlePastedText.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.onTab = this.onTab.bind(this);
        this.onUpArrow = this.onUpArrow.bind(this);
        this.onDownArrow = this.onDownArrow.bind(this);
        this.onLeftArrow = this.onLeftArrow.bind(this);
        this.onRightArrow = this.onRightArrow.bind(this);
        this.onEscape = this.onEscape.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.shouldKeyBind = this.shouldKeyBind.bind(this);
        this.onAndroidChange = this.onAndroidChange.bind(this);

        const isAndroidDevice = isAndroid();
        const androidBlockedWrapper = (fn) => {
            if (isAndroidDevice) {
                console.warn(`${fn.name} unimplemented for Android`);
                return () => { };
            }
            return fn.bind(this);
        };

        this.focus = androidBlockedWrapper(this.focus);
        this.select = androidBlockedWrapper(this.select);
        this.blur = androidBlockedWrapper(this.blur);
        this.clear = androidBlockedWrapper(this.clear);
        this.hasFocus = androidBlockedWrapper(this.hasFocus);
        this.getText = androidBlockedWrapper(this.getText);
        this.selectAll = androidBlockedWrapper(this.selectAll);
        this.getCaratLocation = androidBlockedWrapper(this.getCaratLocation);
        this.getCurrentMentionPhrase = androidBlockedWrapper(this.getCurrentMentionPhrase);
        this.removeMentionPhrase = androidBlockedWrapper(this.removeMentionPhrase);

        const { text } = props;

        const contentState = ContentState.createFromText(text || '');

        const decorator = new CompositeDecorator([]);

        const editorState = EditorState.createWithContent(contentState, decorator);
        this.state = { editorState: editorState, isAndroidDevice: isAndroidDevice };
    }

    componentDidMount() {
        this.componentIsMounted = true;
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        clearTimeout(this.redrawTimer);
    }

    forceRedraw() {
        clearTimeout(this.redrawTimer);
        const delay = 10;
        this.redrawTimer = setTimeout(() => {
            this._redraw(false);
        }, delay);
    }

    forceRedrawAndSelectAll() {
        clearTimeout(this.redrawTimer);
        const delay = 10;
        this.redrawTimer = setTimeout(() => {
            this._redraw(true);
        }, delay);
    }

    focus(offset) {
        this.textField.focus();
        const editorState = this.state.editorState;
        if (offset) {
            const currentContent = editorState.getCurrentContent();
            const block = currentContent.getFirstBlock();
            var selection = SelectionState.createEmpty(block.getKey());
            selection = selection.merge({
                anchorKey: block.getKey(),
                anchorOffset: Math.min(offset, block.getLength()),
                focusKey: block.getKey(),
                focusOffset: Math.min(offset, block.getLength())
            });
            this.setState({ ...this.state, editorState: EditorState.forceSelection(editorState, selection) });
        } else {
            this.setState({ ...this.state, editorState: EditorState.moveFocusToEnd(editorState) });
        }
    }

    select() {
        const { editorState } = this.state;
        const currentContent = editorState.getCurrentContent();
        const block = currentContent.getFirstBlock();
        var selection = SelectionState.createEmpty(block.getKey());
        selection = selection.merge({
            anchorKey: block.getKey(),
            anchorOffset: 0,
            focusKey: block.getKey(),
            focusOffset: block.getLength()
        });

        this.setState({ ...this.state, editorState: EditorState.forceSelection(editorState, selection) });
    }

    blur() {
        this.textField.blur();
    }

    clear() {
        const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
        this.setState({ ...this.state, editorState: editorState });
    }

    // replace(text) {
    //     const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(text));
    //     this.setState({...this.state, editorState: editorState});
    // }

    hasFocus() {
        return this.state.editorState.getSelection().hasFocus;
    }

    getText() {
        return this.state.editorState.getCurrentContent().getPlainText();
    }

    hasText() {
        return this.state.editorState.getCurrentContent().hasText();
    }

    setText(text) {
        const contentState = ContentState.createFromText(text || '');

        const decorator = new CompositeDecorator([]);

        const editorState = EditorState.createWithContent(contentState, decorator);
        this.setState({ ...this.state, editorState: editorState });
    }

    selectAll() {
        const editorState = this.state.editorState;
        const currentContent = editorState.getCurrentContent();
        const firstBlock = currentContent.getFirstBlock();
        const lastBlock = currentContent.getLastBlock();

        let selectAllState = SelectionState.createEmpty(firstBlock.getKey());
        selectAllState = selectAllState.merge({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getLength()
        });

        this.setState({ ...this.state, editorState: EditorState.forceSelection(editorState, selectAllState) });
    }

    getCaratLocation() {
        const selection = window.getSelection();
        if (selection.getRangeAt && selection.rangeCount) {
            const range = selection.getRangeAt(0);
            const rects = range.getClientRects();
            if (rects.length > 0) {
                return rects[0];
            }
        }
    }

    _redraw(shouldSelectAllIfFocused) {
        if (!this.componentIsMounted) { return; }
        const editorState = this.state.editorState;
        let selection = editorState.getSelection();
        // const currentContent = editorState.getCurrentContent();
        // const firstBlock = currentContent.getFirstBlock();
        // const lastBlock = currentContent.getLastBlock();
        // const typingNotAtEnd = selection.hasFocus && (!selection.isCollapsed() || selection.getEndOffset() < firstBlock.getLength());

        // var selectAllState = SelectionState.createEmpty(firstBlock.getKey());
        // selectAllState = selectAllState.merge({
        //     anchorKey: firstBlock.getKey(),
        //     anchorOffset: 0,
        //     focusKey: lastBlock.getKey(),
        //     focusOffset: lastBlock.getLength()
        // });

        const { text } = this.props;
        const newContentState = ContentState.createFromText(text || '');

        var newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
        if (selection.hasFocus) {
            if (shouldSelectAllIfFocused) {
                const firstBlock = newContentState.getFirstBlock();
                const lastBlock = newContentState.getLastBlock();

                selection = selection.merge({
                    anchorKey: firstBlock.getKey(),
                    anchorOffset: 0,
                    focusKey: lastBlock.getKey(),
                    focusOffset: lastBlock.getLength()
                });
            }
            newEditorState = EditorState.forceSelection(newEditorState, selection);
        }
        this.setState({ ...this.state, editorState: newEditorState });
    }

    componentWillReceiveProps(nextProps) {
        if (isEqual(nextProps, this.props)) { this.ignoreProps = true; return; }
        if (nextProps.onReturn !== this.props.onReturn || nextProps.onTab !== this.props.onTab || nextProps.onUpArrow !== this.props.onUpArrow || nextProps.onDownArrow !== this.props.onDownArrow || nextProps.onLeftArrow !== this.props.onLeftArrow || nextProps.onRightArrow !== this.props.onRightArrow || nextProps.onEscape !== this.props.onEscape) {
            //just rerender
        } else if (nextProps.readOnly !== this.props.readOnly || nextProps.placeholder !== this.props.placeholder) {
            clearTimeout(this.redrawTimer);
            this.redrawTimer = setTimeout(() => {
                this._redraw();
            }, 0);
        } else if (nextProps.text !== this.props.text) {
            if (!this.state.editorState.getSelection().hasFocus || this.props.shouldRedrawImmediately) {
                clearTimeout(this.redrawTimer);
                const delay = 10;
                this.redrawTimer = setTimeout(() => {
                    this._redraw();
                }, delay);
            }
        } else {
            this.ignoreProps = true;
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.ignoreProps) {
            this.ignoreProps = false;
            return false;
        }
        return true;
    }

    getCurrentMentionPhrase() {
        const text = this.getText();
        const contentState = this.state.editorState.getCurrentContent();
        const selection = this.state.editorState.getSelection();
        if (selection.isCollapsed) {
            const index = selection.getStartOffset();
            const block = contentState.getBlockForKey(selection.getStartKey());
            const previousMentionTrigger = text.substring(0, index).lastIndexOf('@');
            let mentionPhrase;
            if (previousMentionTrigger > -1 && !block.getEntityAt(previousMentionTrigger)) {
                mentionPhrase = text.substring(previousMentionTrigger, index);
            }
            return mentionPhrase;
        }
    }

    removeMentionPhrase() {
        const contentState = this.state.editorState.getCurrentContent();
        const selection = this.state.editorState.getSelection();
        const text = contentState.getPlainText();
        if (selection.isCollapsed) {
            const textIndex = selection.getStartOffset();
            let previousTrigger = text.substring(0, textIndex).lastIndexOf('@');
            if (previousTrigger > -1) {
                // remove space before as well if necessary
                if (previousTrigger > 0 && text.substring(previousTrigger - 1, previousTrigger) === ' ') {
                    previousTrigger -= 1;
                }
                const wordSelection = selection.merge({
                    anchorOffset: previousTrigger,
                    focusOffset: textIndex
                });
                let newContentState = Modifier.replaceText(contentState, wordSelection, '');
                this.setState({ ...this.state, editorState: EditorState.push(this.state.editorState, newContentState, 'insert-characters') }, () => {
                    const newText = newContentState.getPlainText();
                    if (this.props.onChange) {
                        this.props.onChange(newText);
                    }
                });
            }
        }
    }

    onChange(editorState) {
        var callback;
        if (this.enteredText || editorState.getLastChangeType() === 'undo' || editorState.getLastChangeType() === 'redo' || editorState.getLastChangeType() === 'apply-entity' || editorState.getLastChangeType() === 'remove-range') {
            this.enteredText = false;
            const newText = editorState.getCurrentContent().getPlainText();
            callback = () => {
                if (this.props.onChange) {
                    this.props.onChange(newText);
                }
            };
        }
        this.setState({ ...this.state, editorState: editorState }, callback);
    }

    onAndroidChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    onFocus(editorState) {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    onBlur(editorState) {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    onTab(event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.shiftKey && this.props.onShiftTab) {
            this.props.onShiftTab();
        } else if (this.props.onTab) {
            this.props.onTab();
        }
    }

    onEscape(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onEscape) {
            this.props.onEscape();
        }
    }

    onUpArrow(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onUpArrow) {
            this.props.onUpArrow(this.state.editorState.getSelection().getEndOffset());
        }
    }

    onDownArrow(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onDownArrow) {
            this.props.onDownArrow(this.state.editorState.getSelection().getEndOffset());
        }
    }

    onLeftArrow(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onLeftArrow) {
            this.props.onLeftArrow(this.state.editorState.getSelection().getEndOffset());
        }
    }

    onRightArrow(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onRightArrow) {
            this.props.onRightArrow(this.state.editorState.getSelection().getEndOffset());
        }
    }

    onReturn(event, editorState) {
        if ((event.metaKey || event.altKey || event.metaKey) && this.props.onModReturn) {
            event.preventDefault();
            event.stopPropagation();
            this.props.onModReturn();
            return true;
        } else if (this.props.onReturn) {
            event.preventDefault();
            event.stopPropagation();
            this.props.onReturn(editorState);
            return true;
        }
        return this.props.multiline ? false : true;
    }

    handlePastedText(text, html) {
        this.enteredText = true;
        const { onPaste } = this.props;
        if (onPaste) {
            return !!onPaste(text);
        }
        return false;
    }

    handleBeforeInput(chars) {
        this.enteredText = true;
        return false;
    }

    handleDrop(s, d, i) {
        return true;
    }

    onKeyDown(action) {
        const { onDelete, onComma, onSpacebar, onUndo, onRedo } = this.props;
        if (action === 'text-delete' && onDelete) {
            onDelete();
            return true;
        } else if (action === 'text-comma' && onComma) {
            onComma();
            return true;
        } else if (action === 'text-spacebar' && onSpacebar) {
            onSpacebar();
            return true;
        } else if (action === 'undo-override' && onUndo) {
            onUndo();
            return true;
        } else if (action === 'redo-override' && onRedo) {
            onRedo();
            return true;
        }
        return false;
    }

    shouldKeyBind(event) {
        if (event.keyCode === 8) {
            this.enteredText = true;
            if (this.props.onDelete && !this.state.editorState.getCurrentContent().hasText()) {
                return 'text-delete';
            }
        } else if (event.keyCode === 188) {
            this.enteredText = true;
            if (this.props.onComma) {
                return 'text-comma';
            }
        } else if (event.keyCode === 32) {
            this.enteredText = true;
            if (this.props.onSpacebar) {
                return 'text-spacebar';
            }
        } else if (event.keyCode === 68) {
            this.enteredText = true;
        } else if (isWindows()) {
            if (event.keyCode === 90 && event.ctrlKey) {
                if (this.props.onUndo) {
                    return 'undo-override';
                }
            } else if (event.keyCode === 89 && event.ctrlKey) {
                if (this.props.onRedo) {
                    return 'redo-override';
                }
            }
        } else {
            if (event.keyCode === 90 && event.metaKey && event.shiftKey) {
                if (this.props.onRedo) {
                    return 'redo-override';
                }
            } else if (event.keyCode === 90 && event.metaKey) {
                if (this.props.onUndo) {
                    return 'undo-override';
                }
            }
        }
        return getDefaultKeyBinding(event);
    }

    onRender(input) {
        this.textField = input;
    }

    render() {
        if (this.state.isAndroidDevice) {
            return <Input
                type="text"
                ref={this.onRender}
                placeholder={this.props.placeholder}
                onChange={this.onAndroidChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={this.props.text || ''}
            />;
        }
        return <Editor
            ref={this.onRender}
            readOnly={this.props.readOnly || false}
            spellCheck={this.props.hasOwnProperty('spellCheck') ? this.props.spellCheck : true}
            autoComplete={this.props.autoComplete}
            stripPastedStyles={true}
            placeholder={this.props.placeholder}
            textAlignment={this.props.textAlign || 'left'}
            editorState={this.state.editorState}
            onChange={this.onChange}
            keyBindingFn={this.shouldKeyBind}
            handleKeyCommand={this.onKeyDown}
            handleReturn={this.onReturn}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onTab={this.props.onTab ? this.onTab : undefined}
            onUpArrow={this.props.onUpArrow ? this.onUpArrow : undefined}
            onDownArrow={this.props.onDownArrow ? this.onDownArrow : undefined}
            onLeftArrow={this.props.onLeftArrow ? this.onLeftArrow : undefined}
            onRightArrow={this.props.onRightArrow ? this.onRightArrow : undefined}
            onEscape={this.props.onEscape ? this.onEscape : undefined}
            handleBeforeInput={this.handleBeforeInput}
            handlePastedText={this.handlePastedText}
            handleDrop={this.handleDrop}
        />;
    }
}

export default TextField;
