import React from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';

import { getAuthedGuestInfo } from './authentication';

import iconBackground from './background.jpg';
import iconLaLupita from './lalupita.jpg';
import iconFloraFarms from './florafarms.jpg';
import iconElDorado from './eldorado.jpg';
import iconAzul from './azul.jpg';
import icon1 from './1.jpg';
import icon2 from './2.jpg';
import icon3 from './3.jpg';
import icon4 from './4.jpg';
import icon5 from './5.jpg';
import icon6 from './6.jpg';
import icon7 from './7.jpg';
import icon8 from './8.jpg';
import icon9 from './9.jpg';
import icon10 from './10.jpg';
import icon11 from './11.jpg';
import icon12 from './12.jpg';
import icon13 from './13.jpg';
import icon14 from './14.jpg';
import icon15 from './15.jpg';
import icon16 from './16.jpg';
import icon17 from './17.jpg';
import icon18 from './18.jpg';
import icon19 from './19.jpg';
import icon20 from './20.jpg';

const Root = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #FEFDF8;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const Header = styled.div`
    flex: 0 0 auto;
    height: 60px;
    background-color: white;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.1);
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;

    &:before {
        content: "";
        display: block;
        flex: 1 0 auto;
        align-self: stretch;
        width: 16px;
    }

    &:after {
        content: "";
        display: block;
        flex: 1 0 auto;
        align-self: stretch;
        width: 16px;
    }
`;

const HeaderButton = styled.div`
    flex: 0 0 auto;
    margin: 0 16px;
    font-size: 18px;
    font-weight: ${(props) => props.selected ? 600 : 400};
    color: #333333;
    cursor: pointer;
    position: relative;

    ${(props) => props.unread ? `
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: -13px;
            top: 7px;
            width: 8px;
            height: 8px;
            background-color: #D4C607;
            border-radius: 50%;
            overflow: hidden;
            pointer-events: none;
        }
    ` : ''}
`;

const Content = styled.div`
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const Background = styled.img`
    flex: 0 0 auto;
    object-fit: cover;
`;

const TitleContent = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 60px 0 0 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.div`
    flex: 0 0 auto;
    font-family: 'Rochester', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 130px;
    font-weight: 300;
    color: white;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    pointer-events: none;

    @media (max-width: 1100px) {
        font-size: 100px;
    }

    @media (max-width: 800px) {
        font-size: 80px;
    }

    @media (max-width: 500px) {
        font-size: 70px;
    }
`;

const Description = styled.div`
    flex: 0 0 auto;
    padding: 12px 0 0 0;
    font-size: 24px;
    font-weight: 400;
    color: white;
    line-height: 1.4;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    pointer-events: none;

    @media (max-width: 1100px) {
        font-size: 22px;
    }

    @media (max-width: 800px) {
        font-size: 20px;
    }

    @media (max-width: 500px) {
        font-size: 18px;
    }
`;

const Section = styled.div`
    flex: 0 0 auto;
    padding: 60px 48px 0 48px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-child {
        padding-bottom: 10%;
    }

    &:before {
        content: "";
        display: block;
        flex: 0 0 auto;
        width: 500px;
        max-width: 100%;
        height: 2px;
        margin: 0 0 60px 0;
        background-color: #333333;
        border-radius: 1px;
    }

    @media (max-width: 800px) {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const SectionTitle = styled.div`
    flex: 0 0 auto;
    font-size: 52px;
    font-weight: 600;
    color: #333333;

    @media (max-width: 1100px) {
        font-size: 44px;
    }

    @media (max-width: 800px) {
        font-size: 38px;
    }

    @media (max-width: 500px) {
        font-size: 32px;
    }
`;

const EventSection = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const EventTitle = styled.div`
    flex: 0 0 auto;
    margin: 0 0 16px 0;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
`;

const EventContent = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const EventText = styled.div`
    flex: 1;
    margin: 0 24px 0 0;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;

    @media (max-width: 800px) {
        flex: 0 0 auto;
        margin: 0 0 24px 0;
    }
`;

const EventImage = styled.img`
    flex: 1;
    margin: 0 0 0 24px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0 rgba(0,0,0,0.25);
    overflow: hidden;

    @media (max-width: 800px) {
        flex: 0 0 auto;
        margin: 0;
        width: 100%;
    }
`;

const TravelSection = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const TravelTitle = styled.div`
    flex: 0 0 auto;
    margin: 0 0 16px 0;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
`;

const TravelContent = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const TravelText = styled.div`
    flex: 1;
    margin: 0 24px 0 0;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;

    @media (max-width: 800px) {
        flex: 0 0 auto;
        margin: 0 0 24px 0;
    }
`;

const TravelImage = styled.img`
    flex: 1;
    margin: 0 0 0 24px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0 rgba(0,0,0,0.25);
    overflow: hidden;

    @media (max-width: 800px) {
        flex: 0 0 auto;
        margin: 0;
        width: 100%;
    }
`;

const RegistryText = styled.div`
    flex: 0 0 auto;
    margin: 40px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
`;

const PartySection = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    display: flex;
    align-items: flex-start;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const PartyColumn = styled.div`
    flex: 1;
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: 800px) {
        flex: 1 1 auto;
    }
`;

const PartyColumnTitle = styled.div`
    flex: 0 0 auto;
    margin: 0 0 16px 0;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
    text-align: center;
`;

const PartyColumnText = styled.div`
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    text-align: center;
`;

const PhotosSection = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    margin: 60px 0 0 0;
    position: relative;

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
`;

const PhotoContainer = styled.div`
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Photo = styled.img`
    flex: 0 0 auto;
    @media (max-width: 800px) {
        width: 100%;
        margin: 20px 0 0 0;
    }
`;

const ThingsSection = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const ThingsTitle = styled.div`
    flex: 0 0 auto;
    margin: 0 0 16px 0;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
`;

const ThingsList = styled.ul`
    flex: 0 0 auto;
`;

const ThingsEntry = styled.li`
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
`;

const FAQQuestion = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    margin: 40px 0 8px 0;
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
`;

const FAQAnswer = styled.div`
    flex: 0 0 auto;
    align-self: stretch;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
`;

const ROUTE = {
    HOME: 0,
    EVENTS: 1,
    TRAVEL: 2,
    RSVP: 3,
    REGISTRY: 4,
    PARTY: 5,
    PHOTOS: 6,
    TODO: 7,
    Faq: 8
}

const PHOTOS = [
    { width: 1536, height: 2384, src: icon1 },
    { width: 1538, height: 2048, src: icon2 },
    { width: 2666, height: 2048, src: icon3 },
    { width: 1536, height: 1152, src: icon4 },
    { width: 1957, height: 1488, src: icon5 },
    { width: 1121, height: 1488, src: icon6 },
    { width: 1116, height: 1488, src: icon7 },
    { width: 2256, height: 1673, src: icon8 },
    { width: 1254, height: 1673, src: icon9 },
    { width: 2230, height: 1673, src: icon10 },
    { width: 1537, height: 2049, src: icon11 },
    { width: 2047, height: 2047, src: icon12 },
    { width: 2156, height: 2875, src: icon13 },
    { width: 1959, height: 2612, src: icon14 },
    { width: 1617, height: 1228, src: icon15 },
    { width: 1617, height: 1346, src: icon16 },
    { width: 3606, height: 2026, src: icon17 },
    { width: 2144, height: 3842, src: icon18 },
    { width: 2875, height: 3844, src: icon19 },
    { width: 2875, height: 3844, src: icon20 }
];

// class RsvpBox extends React.Component {
//     shouldComponentUpdate(nextProps, nextState) {
//         if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
//             return false;
//         }
//         return true;
//     }

//     componentDidMount() {
//         // https://weddingnotifier-7639.twil.io/notify
//     }

    
// }

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
        this.onTodoLeave = this.onTodoLeave.bind(this);
        this.onTodoEnter = this.onTodoEnter.bind(this);
        this.onPhotosLeave = this.onPhotosLeave.bind(this);
        this.onPhotosEnter = this.onPhotosEnter.bind(this);
        this.onPartyLeave = this.onPartyLeave.bind(this);
        this.onPartyEnter = this.onPartyEnter.bind(this);
        this.onRegistryLeave = this.onRegistryLeave.bind(this);
        this.onRegistryEnter = this.onRegistryEnter.bind(this);
        this.onRsvpLeave = this.onRsvpLeave.bind(this);
        this.onRsvpEnter = this.onRsvpEnter.bind(this);
        this.onTravelLeave = this.onTravelLeave.bind(this);
        this.onTravelEnter = this.onTravelEnter.bind(this);
        this.onEventsLeave = this.onEventsLeave.bind(this);
        this.onEventsEnter = this.onEventsEnter.bind(this);
        this.onHomeLeave = this.onHomeLeave.bind(this);
        this.onHomeEnter = this.onHomeEnter.bind(this);
        this.onFaqClick = this.onFaqClick.bind(this);
        this.onTodoClick = this.onTodoClick.bind(this);
        this.onPhotosClick = this.onPhotosClick.bind(this);
        this.onPartyClick = this.onPartyClick.bind(this);
        this.onRegistryClick = this.onRegistryClick.bind(this);
        this.onRsvpClick = this.onRsvpClick.bind(this);
        this.onTravelClick = this.onTravelClick.bind(this);
        this.onEventsClick = this.onEventsClick.bind(this);
        this.onHomeClick = this.onHomeClick.bind(this);
        this.onFaqSectionRender = this.onFaqSectionRender.bind(this);
        this.onTodoSectionRender = this.onTodoSectionRender.bind(this);
        this.onPhotosSectionRender = this.onPhotosSectionRender.bind(this);
        this.onPartySectionRender = this.onPartySectionRender.bind(this);
        this.onRegistrySectionRender = this.onRegistrySectionRender.bind(this);
        this.onTravelSectionRender = this.onTravelSectionRender.bind(this);
        this.onEventsSectionRender = this.onEventsSectionRender.bind(this);
        this.onContentRender = this.onContentRender.bind(this);
        this.onRender = this.onRender.bind(this);

        this.state = { route: ROUTE.HOME, redrawToggle: false };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        if (this.resizeTimer) {
            clearTimeout(this.resizeTimer);
        }
        window.removeEventListener('resize', this.onResize);
    }

    hasRsvped() {
        const info = getAuthedGuestInfo();
        if (info && info.rsvped) {
            return true;
        }
        return false;
    }

    onResize(event) {
        if (this.resizeTimer) {
            clearTimeout(this.resizeTimer);
        }
        this.resizeTimer = setTimeout(() => {
            this.setState({ redrawToggle: !this.state.redrawToggle });
        }, 500);
    }

    onTodoLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            this.setState({ route: ROUTE.FAQ });
        }
    }

    onTodoEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.TODO });
        }
    }

    onPhotosLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            this.setState({ route: ROUTE.TODO });
        }
    }

    onPhotosEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.PHOTOS });
        }
    }

    onPartyLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            this.setState({ route: ROUTE.PHOTOS });
        }
    }

    onPartyEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.PARTY });
        }
    }

    onRegistryLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            this.setState({ route: ROUTE.PARTY });
        }
    }

    onRegistryEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.REGISTRY });
        }
    }

    onRsvpLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            this.setState({ route: ROUTE.REGISTRY });
        }
    }

    onRsvpEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.RSVP });
        }
    }

    onTravelLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            // this.setState({ route: ROUTE.RSVP });
            this.setState({ route: ROUTE.REGISTRY });
        }
    }

    onTravelEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.TRAVEL });
        }
    }

    onEventsLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            this.setState({ route: ROUTE.TRAVEL });
        }
    }

    onEventsEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.EVENTS });
        }
    }

    onHomeLeave(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.above && previousPosition === Waypoint.inside) {
            this.setState({ route: ROUTE.EVENTS });
        }
    }

    onHomeEnter(data) {
        const { currentPosition, previousPosition } = data;
        if (currentPosition === Waypoint.inside && previousPosition === Waypoint.above) {
            this.setState({ route: ROUTE.HOME });
        }
    }

    onFaqClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.faqView) {
            this.contentView.scrollTop = this.faqView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.FAQ });
        }, 100);
    }

    onTodoClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.todoView) {
            this.contentView.scrollTop = this.todoView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.TODO });
        }, 100);
    }

    onPhotosClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.photosView) {
            this.contentView.scrollTop = this.photosView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.PHOTOS });
        }, 100);
    }

    onPartyClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.partyView) {
            this.contentView.scrollTop = this.partyView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.PARTY });
        }, 100);
    }

    onRegistryClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.registryView) {
            this.contentView.scrollTop = this.registryView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.REGISTRY });
        }, 100);
    }

    onRsvpClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.rsvpView) {
            this.contentView.scrollTop = this.rsvpView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.RSVP });
        }, 100);
    }

    onTravelClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.travelView) {
            this.contentView.scrollTop = this.travelView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.TRAVEL });
        }, 100);
    }

    onEventsClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView && this.eventsView) {
            this.contentView.scrollTop = this.eventsView.offsetTop;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.EVENTS });
        }, 100);
    }

    onHomeClick(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.contentView) {
            this.contentView.scrollTop = 0;
        }
        setTimeout(() => {
            this.setState({ route: ROUTE.HOME });
        }, 100);
    }

    onFaqSectionRender(faqView) {
        this.faqView = faqView;
    }

    onTodoSectionRender(todoView) {
        this.todoView = todoView;
    }

    onPhotosSectionRender(photosView) {
        this.photosView = photosView;
    }

    onPartySectionRender(partyView) {
        this.partyView = partyView;
    }

    onRegistrySectionRender(registryView) {
        this.registryView = registryView;
    }

    onTravelSectionRender(travelView) {
        this.travelView = travelView;
    }

    onEventsSectionRender(eventsView) {
        this.eventsView = eventsView;
    }

    onContentRender(contentView) {
        this.contentView = contentView;
    }

    onRender(view) {
        this.view = view;
    }

    render() {
        const { route } = this.state;

        let photos = [];
        let locations = [];
        PHOTOS.forEach((photoInfo, index) => {
            if (window.innerWidth <= 800) {
                photos.push(<Photo key={index} src={photoInfo.src} />);
            } else {
                let ratio = (window.innerWidth - 116) / 5740;
                let left = 0;
                let top = 0;
                if (index === 1) {
                    left = locations[0].right + 10;
                } else if (index === 2) {
                    left = locations[1].right + 10;
                } else if (index === 3) {
                    ratio = (window.innerWidth - 126) / 5730;
                    top = locations[0].bottom + 10;
                } else if (index === 4) {
                    ratio = (window.innerWidth - 126) / 5730;
                    left = locations[3].right + 10;
                    top = locations[1].bottom + 10;
                } else if (index === 5) {
                    ratio = (window.innerWidth - 126) / 5730;
                    left = locations[4].right + 10;
                    top = locations[2].bottom + 10;
                } else if (index === 6) {
                    ratio = (window.innerWidth - 126) / 5730;
                    left = locations[5].right + 10;
                    top = locations[2].bottom + 10;
                } else if (index === 7) {
                    top = locations[3].bottom + 10;
                } else if (index === 8) {
                    left = locations[7].right + 10;
                    top = locations[3].bottom + 10;
                } else if (index === 9) {
                    left = locations[8].right + 10;
                    top = locations[3].bottom + 10;
                } else if (index === 10) {
                    top = locations[7].bottom + 10;
                } else if (index === 11) {
                    left = locations[10].right + 10;
                    top = locations[7].bottom + 10;
                } else if (index === 12) {
                    left = locations[11].right + 10;
                    top = locations[7].bottom + 10;
                } else if (index === 13) {
                    top = locations[10].bottom + 10;
                } else if (index === 14) {
                    left = locations[13].right + 10;
                    top = locations[11].bottom + 10;
                } else if (index === 15) {
                    left = locations[13].right + 10;
                    top = locations[14].bottom + 10;
                } else if (index === 16) {
                    ratio = (window.innerWidth - 106) / 5750;
                    top = locations[13].bottom + 10;
                } else if (index === 17) {
                    ratio = (window.innerWidth - 106) / 5750;
                    left = locations[16].right + 10;
                    top = locations[12].bottom + 10;
                } else if (index === 18) {
                    ratio = (window.innerWidth - 106) / 5750;
                    top = locations[16].bottom + 10;
                } else if (index === 19) {
                    ratio = (window.innerWidth - 106) / 5750;
                    left = locations[18].right + 10;
                    top = locations[16].bottom + 10;
                }
                photos.push(
                    <PhotoContainer key={index} style={{ left: `${Math.round(left)}px`, top: `${Math.round(top)}px`, width: Math.round(photoInfo.width * ratio), height: Math.round(photoInfo.height * ratio), backgroundColor: `rgba(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},1.0)`}}>
                        <Photo src={photoInfo.src} style={{width: (photoInfo.width + 60) * ratio}} />
                    </PhotoContainer>
                );
                locations.push({ left: Math.round(left), top: Math.round(top), right: Math.round(left) + Math.round(photoInfo.width * ratio), bottom: Math.round(top) + Math.round(photoInfo.height * ratio) });
            }
        });

        return (
            <Root ref={this.onRender}>
                <Header>
                    <HeaderButton selected={route === ROUTE.HOME} onClick={this.onHomeClick}>{'Home'}</HeaderButton>
                    <HeaderButton selected={route === ROUTE.EVENTS} onClick={this.onEventsClick}>{'Weekend events'}</HeaderButton>
                    <HeaderButton selected={route === ROUTE.TRAVEL} onClick={this.onTravelClick}>{'Travel & lodging'}</HeaderButton>
                    {/* <HeaderButton selected={route === ROUTE.RSVP} unread={!this.hasRsvped()} onClick={this.onRsvpClick}>{'RSVP'}</HeaderButton> */}
                    <HeaderButton selected={route === ROUTE.REGISTRY} onClick={this.onRegistryClick}>{'Registry'}</HeaderButton>
                    <HeaderButton selected={route === ROUTE.PARTY} onClick={this.onPartyClick}>{'Wedding party'}</HeaderButton>
                    <HeaderButton selected={route === ROUTE.PHOTOS} onClick={this.onPhotosClick}>{'Photos'}</HeaderButton>
                    <HeaderButton selected={route === ROUTE.TODO} onClick={this.onTodoClick}>{'Things to do'}</HeaderButton>
                    <HeaderButton selected={route === ROUTE.FAQ} onClick={this.onFaqClick}>{'FAQ'}</HeaderButton>
                </Header>
                <Content ref={this.onContentRender}>
                    <Background src={iconBackground} />
                    <TitleContent id="home">
                        <Title>{'Elizabeth & Adam'}</Title>
                        <Description>{'January 14 - 16, 2022 · Join us in Cabo!'}</Description>
                    </TitleContent>
                    <Waypoint onEnter={this.onHomeEnter} onLeave={this.onHomeLeave} />
                    <Section ref={this.onEventsSectionRender}>
                        <SectionTitle>{'Weekend events'}</SectionTitle>
                        <EventSection>
                            <EventTitle>{'Welcome Party'}</EventTitle>
                            <EventContent>
                                <EventText>
                                    {'La Lupita Tacos & Mezcal'}<br />
                                    {'Friday, January 14th, 2022'}<br />
                                    {'7:00pm-10:00pm'}<br />
                                    <br />
                                    <a href={'http://www.lalupitatym.com'} target={'_blank'} rel={'noreferrer'}>{'http://www.lalupitatym.com'}</a><br />
                                    <a href={'https://maps.apple.com/?address=Playa%20M%C3%A9dano,%20Calle%20Acuario,%20El%20Medano,%2023453%20Los%20Cabos,%20BCS,%20Mexico&auid=18418401235565645601&ll=22.888916,-109.905934&lsp=9902&q=La%20Lupita%20Taco%20%26%20Mezcal&_ext=CisKBQgEEKUBCgQIBRADCgQIBhAUCgQIChAACgQIUhABCgQIVRAOCgQIWRABEiQpc00rJlXjNkAxUKxWywp6W8A5C9ag5MrjNkBBsFOp1+p5W8A%3D'} target={'_blank'} rel={'noreferrer'}>{'Address'}</a><br />
                                    <br />
                                    {'Attire for this event will be beach cocktail. Evenings in Cabo can be chilly, so please plan accordingly.'}<br />
                                    <br />
                                    {'We hope you can join us for tacos & mezcal at one of our favorite Cabo restaurants. There will be appetizers and tacos served, in addition to drinks. Transportation will be provided from the Cabo Azul to La Lupita, and back to the hotel at the end of the night.'}<br />
                                </EventText>
                                <EventImage src={iconLaLupita} />
                            </EventContent>
                        </EventSection>
                        <EventSection>
                            <EventTitle>{'Our Wedding'}</EventTitle>
                            <EventContent>
                                <EventText>
                                    {'Flora Farms'}<br />
                                    {'Saturday, January 15th, 2022'}<br />
                                    {'4:30pm-12:00am'}<br />
                                    <br />
                                    <a href={'https://www.flora-farms.com'} target={'_blank'} rel={'noreferrer'}>{'https://www.flora-farms.com'}</a><br />
                                    <a href={'https://maps.apple.com/?address=Los%20Cabos,%20BCS,%20M%C3%A9xico&auid=4689689244742364309&ll=23.079865,-109.677179&lsp=9902&q=Flora\'s%20Field%20Kitchen&_ext=CisKBQgEEKUBCgQIBRADCgQIBhAUCgQIChAACgQIUhABCgQIVRAOCgQIWRABEiQpHWJ/NlL9NkAx19myE59xW8A5jHYZ4Y0rN0BBKSZNuA5lW8A%3D'} target={'_blank'} rel={'noreferrer'}>{'Address'}</a><br />
                                    <br />
                                    {'Attire for the wedding is formal, gentlemen are welcome to wear suits and ties, while ladies are welcome to wear formal dresses. The ceremony and dinner will take place on grass, while the dance floor is on hardwood, so please keep that in mind with your footwear choice. Evenings in Cabo can be chilly, so please plan accordingly.'}<br />
                                    <br />
                                    {'Transportation will be provided to and from the Cabo Azul hotel starting at 3:50pm. If you are not staying at the Cabo Azul, please arrange your transportation either to the Cabo Azul, or directly to Flora Farms. Transportation will be provided back to the Cabo Azul starting at 10:00pm and going through midnight.'}<br />
                                </EventText>
                                <EventImage src={iconFloraFarms} />
                            </EventContent>
                        </EventSection>
                        <EventSection>
                            <EventTitle>{'Beach Party'}</EventTitle>
                            <EventContent>
                                <EventText>
                                    {'El Dorado Golf & Beach Club'}<br />
                                    {'Sunday, January 16th, 2022'}<br />
                                    {'1:00pm-6:00pm'}<br />
                                    <br />
                                    <a href={'https://eldoradobeachclub.com'} target={'_blank'} rel={'noreferrer'}>{'https://eldoradobeachclub.com'}</a><br />
                                    <a href={'https://maps.apple.com/?address=Carretera%20Cabo%20San%20Lucas-La%20Paz%20Km.%2021,%20Cabo%20San%20Lucas%20BCS,%20M%C3%A9xico&auid=7198954016943958636&ll=22.988131,-109.747367&lsp=9902&q=El%20Dorado%20Golf%20%26%20Beach%20Club&_ext=CiwKBQgEEKUBCgQIBRADCgUIBhCfAgoECAoQAAoECFIQAwoECFUQDwoECFkQAhIkKWXBk0HW5TZAMdKoSvYbdlvAOcY8Kw8SFDdAQStXtcGNaVvA'} target={'_blank'} rel={'noreferrer'}>{'Address'}</a><br />
                                    <br />
                                    {'Attire for this event is casual, so please feel free to come in your bathing suit or beach attire.'}<br />
                                    <br />
                                    {'We hope you can join us for one final celebration for the weekend! Please feel free to come and go as works for your schedule, or join us for the full afternoon. We’ll have pool and beach access for swimming, games, and lounging. There will be light snacks served in addition to drinks.'}<br />
                                </EventText>
                                <EventImage src={iconElDorado} />
                            </EventContent>
                        </EventSection>
                    </Section>
                    <Waypoint onEnter={this.onEventsEnter} onLeave={this.onEventsLeave} />
                    <Section ref={this.onTravelSectionRender}>
                        <SectionTitle>{'Travel & lodging'}</SectionTitle>
                        <TravelSection>
                            <TravelTitle>{'Air Travel'}</TravelTitle>
                            <TravelContent>
                                <TravelText>
                                    {'We recommend flying into Los Cabos International Airport (SJD). Flights will typically arrive and depart from SJD earlier in the day, so please plan for traveling early on your arrival date and leaving in the morning or afternoon or on your departure date. We hope you can stay with us through the beach party on Sunday!'}<br />
                                </TravelText>
                            </TravelContent>
                        </TravelSection>
                        <TravelSection>
                            <TravelTitle>{'Shuttle Transportation'}</TravelTitle>
                            <TravelContent>
                                <TravelText>
                                    {'Transportation to and from the welcome party, wedding, and beach party will be provided on shuttles that will depart from the Cabo Azul Resort. For those planning on staying elsewhere, you can either take an Uber or alternative private transportation to the Cabo Azul Resort and catch a shuttle, or travel directly to the venues yourself.'}<br />
                                </TravelText>
                            </TravelContent>
                        </TravelSection>
                        <TravelSection>
                            <TravelTitle>{'Lodging'}</TravelTitle>
                            <TravelContent>
                                <TravelText>
                                    <b>{'Cabo Azul Resort'}</b><br />
                                    <b>Check-In:</b> {'Friday, January 14, 2022 (or up to 3 days earlier)'}<br />
                                    <b>Check-Out:</b> {'Monday, January 17, 2022 (or up to 3 days later)'}<br />
                                    <b>Use Code:</b> GTIJCUE22<br />
                                    <b>Deadline to take advantage of the discounted room block:</b> {'November 15'}<br />
                                    <a href={'https://www.caboazulresort.com/rooms'} target={'_blank'} rel={'noreferrer'}>{'Website'}</a><br />
                                    <a href={'https://www.diamondresortsandhotels.com/?MKC=GTIJCUE22'} target={'_blank'} rel={'noreferrer'}>{'Booking link'}</a><br />
                                    <a href={'mailto:hotel.reservations@diamondresorts.com'} target={'_blank'} rel={'noreferrer'}>{'Email'}</a><br />
                                    <a href={'https://maps.apple.com/?address=Zona%20Hotelera,%20Paseo%20Malecon%20S/N,%20Lote%2011%20Fonatur.,%2023400%20San%20Jos%C3%A9%20del%20Cabo,%20BCS,%20M%C3%A9xico&auid=14236049844370250061&ll=23.046352,-109.694318&lsp=9902&q=Cabo%20Azul%20Resort&_ext=CisKBQgEEKUBCgQIBRADCgQIBhALCgQIChAACgQIUhADCgQIVRANCgQIWRABEiQpd5zB3730NkAxLadrfrdyW8A5GkYnl/kiN0BB01iU7CdmW8A%3D'} target={'_blank'} rel={'noreferrer'}>{'Address'}</a><br />
                                    <br />
                                    {'There are a number of wonderful hotel & resort options for your stay in Los Cabos. We have reserved a block of rooms for our wedding guests at the Cabo Azul Resort. Cabo Azul is 7 miles south of Los Cabos International Airport (approximately 15 minutes by car), and approximately 10-15 minutes from Flora Farms, where our wedding will take place.'}<br />
                                    <br />
                                    {'Guests will be able to take advantage of the group rates 3 days before 1/14/22 and 3 days after 1/16/22, based on request and availability. If you would like to request additional nights or a different unit type not currently available on the booking link, please email: groups@diamondresorts.com with your full name, the group code, unit type and desired travel dates.'}<br />
                                </TravelText>
                                <TravelImage src={iconAzul} />
                            </TravelContent>
                        </TravelSection>
                    </Section>
                    <Waypoint onEnter={this.onTravelEnter} onLeave={this.onTravelLeave} />
                    <Section ref={this.onRegistrySectionRender}>
                        <SectionTitle>{'Registry'}</SectionTitle>
                        <RegistryText>Your presence with us in Cabo is more than enough, but if you wish to give a gift we have a registry <a href={'http://www.zola.com/registry/elizabethandadamjanuary15'} target={'_blank'} rel={'noreferrer'}>here</a>.</RegistryText>
                    </Section>
                    <Waypoint onEnter={this.onRegistryEnter} onLeave={this.onRegistryLeave} />
                    <Section ref={this.onPartySectionRender}>
                        <SectionTitle>{'Wedding party'}</SectionTitle>
                        <PartySection>
                            <PartyColumn>
                                <PartyColumnTitle>{'Bridal Party'}</PartyColumnTitle>
                                <PartyColumnText>
                                    Jillian Tijerina (Maid of Honor)<br />
                                    Ryan Murphy<br />
                                    Maddy Lyons<br />
                                    Annie Osborne<br />
                                    Abby Mathieson<br />
                                    Samantha Cue<br />
                                    Laura Zingale<br />
                                    Beth Gordon<br />
                                    Kelsey Kreppel<br />
                                    Jessi Liang<br />
                                    Jenna Stencer<br />
                                </PartyColumnText>
                            </PartyColumn>
                            <PartyColumn>
                                <PartyColumnTitle>{'Groomsmen'}</PartyColumnTitle>
                                <PartyColumnText>
                                    Spencer Cue (Best Man)<br />
                                    Paul Jordan<br />
                                    Devon Townsend<br />
                                    Jed Lavery<br />
                                    Dan Murray<br />
                                    Sam Schatz<br />
                                    Jake Sganga<br />
                                    Nick Gubbins<br />
                                    Cody Kolodziejzyk<br />
                                    Marcus Molchany<br />
                                    Juan San Juan<br />
                                </PartyColumnText>
                            </PartyColumn>
                        </PartySection>
                    </Section>
                    <Waypoint onEnter={this.onPartyEnter} onLeave={this.onPartyLeave} />
                    <Section ref={this.onPhotosSectionRender}>
                        <SectionTitle>{'Photos'}</SectionTitle>
                        <PhotosSection style={locations.length > 0 ? { height: `${locations[18].bottom}px` } : undefined}>
                            {photos}
                        </PhotosSection>
                    </Section>
                    <Waypoint onEnter={this.onPhotosEnter} onLeave={this.onPhotosLeave} />
                    <Section ref={this.onTodoSectionRender}>
                        <SectionTitle>{'Things to do'}</SectionTitle>
                        <ThingsSection>
                            <ThingsTitle>{'Where to Eat & Drink'}</ThingsTitle>
                            <ThingsList>
                                <ThingsEntry><a href={'http://www.lalupitatym.com'} target={'blank'} rel={'noreferrer'}>La Lupita Taco & Mezcal (San Jose del Cabo)</a>: Our favorite  tacos and cocktails in a casual indoor / outdoor environment with live music. There are two locations - our Welcome Party on Friday will be located at the La Lupita in downtown Los Cabos; this location is the original and located in a beautiful stretch of San Jose.</ThingsEntry>
                                <ThingsEntry><a href={'https://edithscabo.com'} target={'blank'} rel={'noreferrer'}>Edith’s</a>: Delicious traditional Mexican food in a colorful, lively atmosphere in downtown Cabo.</ThingsEntry>
                                <ThingsEntry><a href={'https://torocabo.com'} target={'blank'} rel={'noreferrer'}>Toro Latin Kitchen & Bar</a>: Latin American small plates with great seafood and a beautiful terrace.</ThingsEntry>
                                <ThingsEntry><a href={'https://www.flora-farms.com'} target={'blank'} rel={'noreferrer'}>Flora Farms</a>: Our wedding venue! The best farm-to-table restaurant in Cabo, with delicious cocktails and family-style dining in a lively and joyful atmosphere.</ThingsEntry>
                                <ThingsEntry><a href={'https://acrebaja.com'} target={'blank'} rel={'noreferrer'}>Acre Baja</a>: Another beautiful farm-to-table restaurant and resort with a beautiful interior and an onsite mezcaleria.</ThingsEntry>
                                <ThingsEntry><a href={'https://www.thompsonhotels.com/hotels/mexico/cabo-san-lucas/the-cape/restaurants/the-rooftop'} target={'blank'} rel={'noreferrer'}>The Rooftop at The Cape</a>: Our favorite rooftop in Cabo, with stunning  sunset views, delicious snacks and cocktails, and live music.</ThingsEntry>
                                <ThingsEntry><a href={'https://www.surcabo.com/home/#eat'} target={'blank'} rel={'noreferrer'}>SUR Beach House</a>: Laid-back beachside dining right in downtown with great  views of the Cabo arches.</ThingsEntry>
                                <ThingsEntry><a href={'https://www.oneandonlyresorts.com/palmilla/dining/agua'} target={'blank'} rel={'noreferrer'}>Agua Terrace at Palmilla</a>: Outdoor dining with a tropical vibe overlooking the water at Palmilla resort.</ThingsEntry>
                                <ThingsEntry><a href={'https://www.theofficeonthebeach.com'} target={'blank'} rel={'noreferrer'}>The Office on the Beach</a>: Lively and casual beachfront dining overlooking the Cabo San Lucas Bay.</ThingsEntry>
                                <ThingsEntry><a href={'https://www.waldorfastorialoscabospedregal.com/dining/elfarallon'} target={'blank'} rel={'noreferrer'}>El Farallon</a>: Romantic seafood restaurant along the cliffs at the Pedregal resort.</ThingsEntry>
                            </ThingsList>
                        </ThingsSection>
                        <ThingsSection>
                            <ThingsTitle>{'Where to Stay'}</ThingsTitle>
                            <ThingsList>
                                <ThingsEntry><a href={'https://www.caboazulresort.com'} target={'blank'} rel={'noreferrer'}>Cabo Azul</a>: We strongly recommend booking a room at the Cabo Azul both because we have a discounted room block, and because we will be providing transportation to and from the Cabo Azul for all the weekend events. The Cabo Azul has beach access, a seaside dining restaurant, a full spa, multiple pools, and a fitness center and their website includes recommendations for golf and water sports.</ThingsEntry>
                                <ThingsEntry><a href={'https://www.thompsonhotels.com/hotels/mexico/cabo-san-lucas/the-cape'} target={'blank'} rel={'noreferrer'}>The Cape</a></ThingsEntry>
                                <ThingsEntry><a href={'https://www.rosewoodhotels.com/en/las-ventanas-los-cabos'} target={'blank'} rel={'noreferrer'}>Las Ventanas al Paraiso</a></ThingsEntry>
                                <ThingsEntry><a href={'https://www.oneandonlyresorts.com/palmilla'} target={'blank'} rel={'noreferrer'}>One&Only Palmilla</a></ThingsEntry>
                                <ThingsEntry><a href={'https://www.waldorfastorialoscabospedregal.com'} target={'blank'} rel={'noreferrer'}>The Resort at Pedregal</a></ThingsEntry >
                            </ThingsList>
                        </ThingsSection>
                        <ThingsSection>
                            <ThingsTitle>{'Where to Play'}</ThingsTitle>
                            <ThingsList>
                                <ThingsEntry><a href={'https://www.quiviraloscabos.com/golf'} target={'blank'} rel={'noreferrer'}>Quivira Golf Club</a></ThingsEntry>
                                <ThingsEntry><a href={'https://diamantecabosanlucas.com/golf/'} target={'blank'} rel={'noreferrer'}>Golf at Diamante Cabo San Lucas</a></ThingsEntry>
                                <ThingsEntry><a href={'https://bigmikeseadventures.com'} target={'blank'} rel={'noreferrer'}>Big Mike’s Sea Adventures</a> (for boat rentals, whale watching, and snorkeling)</ThingsEntry>
                            </ThingsList>
                        </ThingsSection>
                    </Section>
                    <Waypoint onEnter={this.onTodoEnter} onLeave={this.onTodoLeave} />
                    <Section ref={this.onFaqSectionRender}>
                        <SectionTitle>{'FAQ'}</SectionTitle>
                        <FAQQuestion>What is the dress code?</FAQQuestion>
                        <FAQAnswer>The attire for our wedding is formal. Gentlemen are welcome to wear suits and ties, and ladies are welcome to wear formal dresses or jumpsuits. The ceremony and reception will take place on grass, so please plan your footwear accordingly. The attire for our Welcome Party on Friday is beach cocktail (gentlemen in suits with or without jackets and ties, ladies in dresses or jumpsuits). The attire for our Day-After Beach Party is casual, and bathing suits are welcome.</FAQAnswer>
                        <FAQQuestion>Are children invited to the weekend’s events?</FAQQuestion>
                        <FAQAnswer>While we love your little ones, all of the wedding weekend events are adults-only. We appreciate you making arrangements ahead of time so you can celebrate with us.</FAQAnswer>
                        <FAQQuestion>Will transportation be provided for the weekend’s events?</FAQQuestion>
                        <FAQAnswer>Transportation will be provided from El Dorado Golf & Beach Club and the Cabo Azul on Friday (to and from the Welcome Party at La Lupita), on Saturday (to and from the wedding at Flora Farms), and on Sunday (to and from the Day-After Beach Party at El Dorado). Please note that Uber is also available in Los Cabos, and private transportation to and from San Jose International Airport can also be arranged via the same company we are using for group transportation, <a href={'https://www.impalacabo.com/index.html'} target={'blank'} rel={'noreferrer'}>Impala.</a></FAQAnswer>
                        <FAQQuestion>Where is your hotel room block and how can I reserve a room?</FAQQuestion>
                        <FAQAnswer>You can take advantage of a discounted room block at the Cabo Azul by booking here: <a href={'https://www.diamondresortsandhotels.com/?MKC=GTIJCUE22'} target={'_blank'} rel={'noreferrer'}>https://www.diamondresortsandhotels.com/?MKC=GTIJCUE22, and using the code “GTIJCUE22” through November 15th.</a></FAQAnswer>
                    </Section>
                </Content>
            </Root>
        );
    }
}

export default Main;