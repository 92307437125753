
import * as LocalStorage from './localStorage';

const guests = {
    '0': {
        names: ['Adam Cue', 'Elizabeth Tijerina'],
        bridal_party: true,
        rsvped: false
    },
    '1': {
        names: ['Phil Schiller', 'Kim Schiller'],
        bridal_party: false,
        rsvped: false
    },
    '2': {
        names: ['Nicki Adler', 'Alex Chouraqi'],
        bridal_party: false,
        rsvped: false
    },
    '3': {
        names: ['Johnny Aguirre'],
        bridal_party: false,
        rsvped: false
    },
    '4': {
        names: ['Tariq Ahmed', 'Cheryl Cho'],
        bridal_party: false,
        rsvped: false
    },
    '5': {
        names: ['Asad Ahmed'],
        bridal_party: false,
        rsvped: false
    },
    '6': {
        names: ['Perry Alexander'],
        bridal_party: false,
        rsvped: false
    },
    '7': {
        names: ['Raanah Amjadi'],
        bridal_party: false,
        rsvped: false
    },
    '8': {
        names: ['Bob Anderson', 'Alice Anderson'],
        additional_names: ['Alice Rand'],
        bridal_party: false,
        rsvped: false
    },
    '9': {
        names: ['Ben Bates'],
        bridal_party: false,
        rsvped: false
    },
    '10': {
        names: ['Sean Beausoleil', 'Mandy Beausoleil'],
        bridal_party: false,
        rsvped: false
    },
    '11': {
        names: ['Catherine Bittar'],
        bridal_party: false,
        rsvped: false
    },
    '12': {
        names: ['Dylan Brown', 'Sasha Rezaie'],
        bridal_party: false,
        rsvped: false
    },
    '13': {
        names: ['Lauren Budorick'],
        bridal_party: false,
        rsvped: false
    },
    '14': {
        names: ['Scott Cannon', 'Joyce Cannon'],
        bridal_party: false,
        rsvped: false
    },
    '15': {
        names: ['Cameron Chase', 'Caitlin Whelan'],
        additional_names: ['Cam Chase'],
        bridal_party: false,
        rsvped: false
    },
    '16': {
        names: ['Matthew Chase'],
        bridal_party: false,
        rsvped: false
    },
    '17': {
        names: ['Eddy Cue', 'Paula Cue'],
        bridal_party: true,
        rsvped: false
    },
    '18': {
        names: ['Samantha Cue'],
        bridal_party: true,
        rsvped: false
    },
    '19': {
        names: ['Eduardo Cue', 'Lina Cue'],
        additional_names: ['Belo Cue', 'Belo'],
        bridal_party: true,
        rsvped: false
    },
    '20': {
        names: ['Spencer Cue', 'Jenna Stencer'],
        bridal_party: true,
        rsvped: false
    },
    '21': {
        names: ['Shibani Das', 'Jeff Oh'],
        bridal_party: false,
        rsvped: false
    },
    '22': {
        names: ['Sam Debrule', 'Liz Jung'],
        bridal_party: false,
        rsvped: false
    },
    '23': {
        names: ['Tony DeVincenzi', 'Sara Lannin'],
        additional_names: ['Anthony DeVincenzi', 'Sara DeVincenzi'],
        bridal_party: false,
        rsvped: false
    },
    '24': {
        names: ['Mat Doan'],
        additional_names: ['Mathew Doan', 'Matthew Doan', 'Matt Doan'],
        bridal_party: false,
        rsvped: false
    },
    '25': {
        names: ['Colin Dunn', 'Andi Pimentel'],
        additional_names: ['Andi Dunn'],
        bridal_party: false,
        rsvped: false
    },
    '26': {
        names: ['Guillermo Echarte'],
        bridal_party: false,
        rsvped: false
    },
    '27': {
        names: ['Sam Eisenstat'],
        additional_names: ['Efrat'],
        bridal_party: false,
        rsvped: false
    },
    '28': {
        names: ['Alex Fitzgerald'],
        bridal_party: false,
        rsvped: false
    },
    '29': {
        names: ['Nicole Franklin', 'Adam Franklin'],
        bridal_party: false,
        rsvped: false
    },
    '30': {
        names: ['Steven Galanis', 'Emily Linett'],
        additional_names: ['Steve Galanis'],
        bridal_party: false,
        rsvped: false
    },
    '31': {
        names: ['Nat Garcia', 'Sam Schatz'],
        additional_names: ['Natalie Garcia', 'Steven Newport', 'Steve Newport', 'Samuel Schatz'],
        bridal_party: true,
        rsvped: false
    },
    '32': {
        names: ['Claire Gilhuly', 'Andrew Young'],
        bridal_party: false,
        rsvped: false
    },
    '33': {
        names: ['Claire Godwin', 'Nathaniel Godwin'],
        bridal_party: false,
        rsvped: false
    },
    '34': {
        names: ['Zach Goldstzejn', 'Brooks Hosfield'],
        bridal_party: false,
        rsvped: false
    },
    '35': {
        names: ['Beth Gordon', 'Andy Henderson'],
        bridal_party: true,
        rsvped: false
    },
    '36': {
        names: ['Shelby Greeley'],
        bridal_party: false,
        rsvped: false
    },
    '37': {
        names: ['Nick Gubbins', 'Terouz Pasha'],
        bridal_party: true,
        rsvped: false
    },
    '38': {
        names: ['Megan Hammel', 'Matt Hammel'],
        bridal_party: false,
        rsvped: false
    },
    '39': {
        names: ['Sean Hoag', 'Rainey Lancaster'],
        bridal_party: false,
        rsvped: false
    },
    '40': {
        names: ['Patricia Howard', 'Ed Szymanski'],
        bridal_party: false,
        rsvped: false
    },
    '41': {
        names: ['Philonda Johnson'],
        bridal_party: false,
        rsvped: false
    },
    '42': {
        names: ['Frank Jones', 'Marie Vazelakis'],
        bridal_party: false,
        rsvped: false
    },
    '43': {
        names: ['Paul Jordan', 'Alex Jordan'],
        bridal_party: true,
        rsvped: false
    },
    '44': {
        names: ['Steve Kane'],
        bridal_party: false,
        rsvped: false
    },
    '45': {
        names: ['Meredith Kelly'],
        bridal_party: false,
        rsvped: false
    },
    '46': {
        names: ['Alex Kluge'],
        additional_names: ['Alexander Kluge'],
        bridal_party: false,
        rsvped: false
    },
    '47': {
        names: ['Matthew Knight'], //todo
        bridal_party: false,
        rsvped: false
    },
    '48': {
        names: ['Anna Kohnen', 'Dan Lasowski'],
        additional_names: ['Anna Lasowski'],
        bridal_party: false,
        rsvped: false
    },
    '49': {
        names: ['Cody Kolodziejzyk', 'Kelsey Kreppel'],
        additional_names: ['Cody Ko'],
        bridal_party: true,
        rsvped: false
    },
    '50': {
        names: ['Alex Krinsky'],
        bridal_party: false,
        rsvped: false
    },
    '51': {
        names: ['Chad Lancaster', 'Laura Lancaster'],
        bridal_party: false,
        rsvped: false
    },
    '52': {
        names: ['Colby Leachman', 'Anjela Latcheva'],
        bridal_party: false,
        rsvped: false
    },
    '53': {
        names: ['Jed Lavery', 'Yanyun Xiao'],
        additional_names: ['Harper Xiao'],
        bridal_party: true,
        rsvped: false
    },
    '54': {
        names: ['Arthur Leopold', 'Lindsay Leopold'],
        bridal_party: false,
        rsvped: false
    },
    '55': {
        names: ['Dori Levy'],
        bridal_party: false,
        rsvped: false
    },
    '56': {
        names: ['Jessi Liang', 'Scott Townsend'],
        bridal_party: true,
        rsvped: false
    },
    '57': {
        names: ['Brad Lightcap'],
        bridal_party: false,
        rsvped: false
    },
    '58': {
        names: ['Maddy Lyons', 'John McDonald'],
        bridal_party: true,
        rsvped: false
    },
    '59': {
        names: ['Mike Madding', 'Claire Becker'],
        additional_names: ['Michael Madding'],
        bridal_party: false,
        rsvped: false
    },
    '60': {
        names: ['Rick Marron'], //todo
        additional_names: ['Dick Marron'],
        bridal_party: false,
        rsvped: false
    },
    '61': {
        names: ['Abby Mathieson', 'Levi Malik'],
        bridal_party: true,
        rsvped: false
    },
    '62': {
        names: ['Connor McKhann', 'Aileen Johnson'],
        bridal_party: false,
        rsvped: false
    },
    '63': {
        names: ['Luke Menken', 'Mia Menken'],
        additional_names: ['Mia Brady'],
        bridal_party: false,
        rsvped: false
    },
    '64': {
        names: ['Isaac Mizrahi'],
        bridal_party: false,
        rsvped: false
    },
    '65': {
        names: ['Marcus Molchany', 'Janelle Lew'],
        bridal_party: true,
        rsvped: false
    },
    '66': {
        names: ['Mikey Moritz'],
        bridal_party: false,
        rsvped: false
    },
    '67': {
        names: ['Ryan Murphy'],
        bridal_party: true,
        rsvped: false
    },
    '68': {
        names: ['Dan Murray', 'Georgia Zimmerman'],
        additional_names: ['Krystal'],
        bridal_party: true,
        rsvped: false
    },
    '69': {
        names: ['Tim Nixon', 'Tori Nixon'],
        additional_names: ['Tori Woodward'],
        bridal_party: false,
        rsvped: false
    },
    '70': {
        names: ['Annie Osborne', 'Casey Donahue'],
        bridal_party: true,
        rsvped: false
    },
    '71': {
        names: ['Graham Oxley', 'Juli Palomba'],
        additional_names: ['Juli Oxley'],
        bridal_party: false,
        rsvped: false
    },
    '72': {
        names: ['James Pardee', 'Molly Weinstock'],
        additional_names: ['Molly Pardee'],
        bridal_party: false,
        rsvped: false
    },
    '73': {
        names: ['Joanna Valk', 'Anthony Polemis'],
        additional_names: ['Joanna Valk'],
        bridal_party: false,
        rsvped: false
    },
    '74': {
        names: ['Belinda Preno', 'Nan Yu'],
        bridal_party: false,
        rsvped: false
    },
    '75': {
        names: ['Erin Schulz', 'Cam Schulz'],
        additional_names: ['Erin Radley'],
        bridal_party: false,
        rsvped: false
    },
    '76': {
        names: ['Ethan Rosenblum'],
        bridal_party: false,
        rsvped: false
    },
    '77': {
        names: ['Gideon Rosenthal', 'Maddy Rothman'],
        bridal_party: false,
        rsvped: false
    },
    '78': {
        names: ['Morgan Russo', 'Tim Russo'],
        bridal_party: false,
        rsvped: false
    },
    '79': {
        names: ['Ned Ruter', 'Anjali Shetty'],
        bridal_party: false,
        rsvped: false
    },
    '80': {
        names: ['Juan San Juan', 'Jessa Miller'],
        additional_names: ['Jessa San Jessa'],
        bridal_party: true,
        rsvped: false
    },
    '81': {
        names: ['Ryan Sandler'],
        bridal_party: false,
        rsvped: false
    },
    '82': {
        names: ['Jake Sganga', 'Carissa Sganga'],
        bridal_party: true,
        rsvped: false
    },
    '83': {
        names: ['Nick Singarella', 'Olivia Singarella'], //todo
        bridal_party: true,
        rsvped: false
    },
    '84': {
        names: ['Colette Soloff'],
        bridal_party: false,
        rsvped: false
    },
    '85': {
        names: ['Adam Sommer', 'Alyssa Ponzo'],
        bridal_party: false,
        rsvped: false
    },
    '86': {
        names: ['Zach Sperling'],
        bridal_party: false,
        rsvped: false
    },
    '87': {
        names: ['Cedric Stapleton'],
        bridal_party: false,
        rsvped: false
    },
    '88': {
        names: ['Ross Sylvester', 'Emily Boniferro'],
        additional_names: ['Emily Sylvester'],
        bridal_party: false,
        rsvped: false
    },
    '89': {
        names: ['Derly Tijerina', 'Celie Tijerina'],
        bridal_party: true,
        rsvped: false
    },
    '90': {
        names: ['Jillian Tijerina'],
        bridal_party: true,
        rsvped: false
    },
    '91': {
        names: ['Chris Todd'], //todo
        bridal_party: false,
        rsvped: false
    },
    '92': {
        names: ['Devon Townsend', 'Mary Clayton'],
        bridal_party: true,
        rsvped: false
    },
    '93': {
        names: ['Bo Triplett'],
        bridal_party: false,
        rsvped: false
    },
    '94': {
        names: ['Jan Trnka'],
        bridal_party: false,
        rsvped: false
    },
    '95': {
        names: ['Gentry Underwood', 'Mica Underwood'],
        bridal_party: false,
        rsvped: false
    },
    '96': {
        names: ['Graham Vehovec', 'Sarah Dalton'],
        bridal_party: false,
        rsvped: false
    },
    '97': {
        names: ['Teresa Velez'],
        bridal_party: false,
        rsvped: false
    },
    '98': {
        names: ['Domi Villegas', 'Dominique Villegas'],
        bridal_party: false,
        rsvped: false
    },
    '99': {
        names: ['Blair White'],
        bridal_party: false,
        rsvped: false
    },
    '100': {
        names: ['Jordan Zaslav', 'Ashley Zaslav'],
        bridal_party: false,
        rsvped: false
    },
    '101': {
        names: ['Laura Zingale', 'Tom Zingale'],
        bridal_party: true,
        rsvped: false
    },
    '102': {
        names: ['Jan Trnka'],
        bridal_party: false,
        rsvped: false
    },
    '103': {
        names: ['Bob Schluter', 'Mollie Schluter'],
        bridal_party: false,
        rsvped: false
    },
    '104': {
        names: ['Kay McHorse', 'Tom McHorse'],
        bridal_party: false,
        rsvped: false
    },
    '105': {
        names: ['Mark Vieira', 'Tony Vieira'],
        bridal_party: false,
        rsvped: false
    },
    '106': {
        names: ['Tony Vieira', 'Deborah Kilpatrick'],
        bridal_party: false,
        rsvped: false
    },
    '107': {
        names: ['Marlyse Vieira'],
        bridal_party: false,
        rsvped: false
    },
    '108': {
        names: ['George Henry'],
        bridal_party: false,
        rsvped: false
    },
    '109': {
        names: ['Scott Dillingham', 'Deborah Dillingham'],
        bridal_party: false,
        rsvped: false
    },
    '110': {
        names: ['Jennifer Parker', 'Chad Black'],
        bridal_party: false,
        rsvped: false
    },
    '111': {
        names: ['Marlyse Vieira'],
        bridal_party: false,
        rsvped: false
    },
    '112': {
        names: ['Pam Olsen', 'Todd Olsen'],
        bridal_party: false,
        rsvped: false
    },
    '113': {
        names: ['Danny Tijerina', 'Cindy Tijerina'],
        bridal_party: false,
        rsvped: false
    },
    '114': {
        names: ['Dick Dillingham', 'Judy Dillingham'],
        bridal_party: false,
        rsvped: false
    },
    '115': {
        names: ['Randy Wood', 'Leigh Ann Wood'],
        bridal_party: false,
        rsvped: false
    },
    '116': {
        names: ['Terri Peratt', 'Robbie McNamara'], //todo
        bridal_party: false,
        rsvped: false
    },
    '117': {
        names: ['Rodney Peratt', 'Lauren Peratt'],
        bridal_party: false,
        rsvped: false
    },
    '118': {
        names: ['Barry Peratt', 'Laura Peratt'],
        bridal_party: false,
        rsvped: false
    },
    '119': {
        names: ['Jeff Peratt', 'Nancy Peratt'],
        bridal_party: false,
        rsvped: false
    },
    '120': {
        names: ['Mary Peratt'],
        bridal_party: false,
        rsvped: false
    },
    '121': {
        names: ['Rodney Peratt', 'Lauren Peratt'],
        bridal_party: false,
        rsvped: false
    },
    '122': {
        names: ['Irving Azoff', 'Shelli Azoff'],
        bridal_party: false,
        rsvped: false
    },
    '123': {
        names: ['Bobbi Janes', 'Randi Janes'],
        bridal_party: false,
        rsvped: false
    },
    '124': {
        names: ['David Janes', 'Stephanie Janes'],
        bridal_party: false,
        rsvped: false
    },
    '125': {
        names: ['John Rabasa', 'Anne Michelle Rabasa'],
        bridal_party: false,
        rsvped: false
    },
    '126': {
        names: ['Jim Dahl', 'Kathy Dahl'],
        bridal_party: false,
        rsvped: false
    },
    '127': {
        names: ['Fred Harman', 'Stephanie Harman'],
        bridal_party: false,
        rsvped: false
    },
    '128': {
        names: ['Randy Nichols', 'Darby Nichols'],
        bridal_party: false,
        rsvped: false
    },
    '129': {
        names: ['Mike Cragg', 'Paige Cragg'],
        bridal_party: false,
        rsvped: false
    },
    '130': {
        names: ['Rich Rosenblatt', 'Lisa Rosenblatt'],
        bridal_party: false,
        rsvped: false
    },
    '131': {
        names: ['Pam Johnson', 'Kevin Johnson'],
        bridal_party: false,
        rsvped: false
    },
    '132': {
        names: ['Joe Riera', 'Barb Riera'],
        bridal_party: false,
        rsvped: false
    },
    '133': {
        names: ['Tim Cook'],
        bridal_party: false,
        rsvped: false
    },
    '134': {
        names: ['Jeff Williams', 'Melissa Williams'],
        bridal_party: false,
        rsvped: false
    },
    '135': {
        names: ['Kip Frey', 'Meredith Frey'],
        bridal_party: false,
        rsvped: false
    },
    '136': {
        names: ['Doug Lowey', 'Ellen Lowey'],
        bridal_party: false,
        rsvped: false
    },
    '137': {
        names: ['Mike Meldman'],
        bridal_party: false,
        rsvped: false
    },
    '138': {
        names: ['Lew Cohen', 'Karen Cohen'],
        bridal_party: false,
        rsvped: false
    },
    '139': {
        names: ['Jeff Robbin', 'Laura Robbin'],
        bridal_party: false,
        rsvped: false
    }
};

export function isAuthenticated() {
    return !!LocalStorage.getValue(LocalStorage.AUTHENTICATED_GUEST_ID);
}

export function authenticateWithName(name) {
    if (!name || name.length === 0) {
        return false;
    }
    const searchName = name.toLowerCase().replace(/\s/g, '');
    const guestIDs = Object.keys(guests);
    for (const potentialGuestID of guestIDs) {
        const potentialGuest = guests[potentialGuestID];
        const potentialNames = (potentialGuest.names || []).concat(potentialGuest.additional_names || []);
        for (const potentialName of potentialNames) {
            const potentialSearchName = potentialName.toLowerCase().replace(/\s/g, '');
            if (searchName === potentialSearchName) {
                LocalStorage.setValue(LocalStorage.AUTHENTICATED_GUEST_ID, potentialGuestID);
                return true;
            }
        }
    }
    return false;
};

export function getAuthedGuestInfo() {
    const authenticatedGuestID = LocalStorage.getValue(LocalStorage.AUTHENTICATED_GUEST_ID);
    if (authenticatedGuestID) {
        return guests[authenticatedGuestID];
    }
}
